import React, {useContext} from 'react';
import Background from "../../components/Background/Background";
import './about.css'
import PageTitle from "../../components/PageTitle/PageTitle";
import Banner from "../../components/Banner/Banner";
import CardServices from "../../components/CardServices/CardServices";
import SimpleFeedback from "../../components/SimpleFeedback/SimpleFeedback";
import UnableGasAlert from "../../components/UnableGasAlert/UnableGasAlert";
import {ContentContext} from '../../App'
import {SplideSlide} from "@splidejs/react-splide";


const About = () => {

    const content = useContext(ContentContext);

    return (
        <div className={'about'}>
            <UnableGasAlert/>
            <Background/>
            <span style={{padding:'80px 0 0 0', display:'block'}}></span>
            <main className={'content'}>
                <Banner/>
                <PageTitle
                    title={'О⠀компании'}
                    text={'ООО "ДК ПРОФИ" — ваш надежный партнер в области газификации объектов в Москве и Московской области. \n' +
                        'Мы предоставляем полный спектр услуг по проектированию, монтажу и поддержке систем газоснабжения \n' +
                        'для частных домовладений, многоквартирных жилых комплексов и промышленных предприятий. Наша миссия \n' +
                        '— обеспечивать безопасность и комфорт наших клиентов через качественное и доступное газоснабжение. \n'}
                />
                <br/>
                <p className={'pageTitle__text pageTitle__text_media'}
                   style={{color: "white", fontFamily: 'Montserrat, sans-serif', fontWeight: 'normal'}}>
                    Компания "ДК-Профи" выделяется на рынке строительства и проектирования газопроводов для объектов
                    капитального строительства благодаря своему комплексному подходу и высокому уровню профессионализма.
                    Мы предлагаем полный спектр услуг, начиная с проектирования и заканчивая пусконаладочными работами,
                    обеспечивая надежное и эффективное функционирование газовых систем.
                </p>
                <PageTitle
                    title={'Наш опыт'}
                    text={'С момента основания в 2020 году, мы реализовали более 500 проектов по газификации. Наши специалисты\n' +
                        '— это высококвалифицированные инженеры и техники, которые регулярно проходят обучение и сертификацию \n' +
                        'для работы с новейшими технологиями и оборудованием. Мы гарантируем высокое качество выполненных работ \n' +
                        'и строгое соблюдение всех норм и стандартов безопасности.'} a
                />
                <br/>
                <p className={'pageTitle__text pageTitle__text_media'}
                   style={{color: "white", fontFamily: 'Montserrat, sans-serif', fontWeight: 'normal'}}>
                    Выбирая "ДК-Профи", вы получаете не только качественное выполнение работ, но и уверенность в надежности вашей газификационной системы на многие годы вперед.
                </p>
                {/*<Personal_p/>*/}
                <PageTitle
                    title={'Наши преимущества'}
                />
                <div className="section-about__paragraph-list about__section-about__paragraph-list">
                    <div>
                        <h3>ИНТЕГРИРОВАННЫЙ ПОДХОД</h3>
                        <p>Мы предоставляем комплексные решения от начального проектирования до введения в эксплуатацию,
                            что позволяет нашим клиентам избежать необходимости обращаться к нескольким подрядчикам.</p>
                    </div>
                    <div>
                        <h3>ВЫСОКИЙ УРОВЕНЬ ПРОФЕССИОНАЛИЗМА</h3>
                        <p>Наш коллектив состоит из опытных специалистов, готовых решать любые технические и
                            организационные задачи, связанные с газификацией.</p>
                    </div>
                    <div>
                        <h3>СОБЛЮДЕНИЕ СРОКОВ И СТАНДАРТОВ</h3>
                        <p>Мы гарантируем выполнение работ в строго установленные сроки и с соблюдением всех нормативных
                            требований и стандартов качества.</p>
                    </div>
                    <div>
                        <h3>ТЕХНОЛОГИЧЕСКАЯ КОМПЕТЕНТНОСТЬ</h3>
                        <p>Мы используем современное оборудование и передовые технологии, что обеспечивает надежность и
                            безопасность наших газопроводов.</p>
                    </div>
                </div>
                <PageTitle
                    title={'Оказываем полный цикл услуг'}
                    text={'Быстрая газификация в течение 1 месяца с момента получения технических условий, при условии готовности котельной, установленных окон и дверей, установленного котла, выведенного дымохода от котла и вентиляционного канала.\n' +
                        'Профессиональный и опытный коллектив, обеспечивающий высокое качество работ и соблюдение всех нормативов и стандартов.'}
                />
                <span className={'media_delay'}></span>
                <div className={'services__card-box'}>
                    {/*{content.services.map((value, index)=>{*/}
                    {/*    return(*/}
                    {/*        content.about_id.map((val_id, i )=>{*/}
                    {/*            if(val_id === value.id){*/}
                    {/*                console.log(val_id === value.id)*/}
                    {/*                return (*/}
                    {/*                    <CardServices*/}
                    {/*                        key={"card_about_" + index + '__'+ i}*/}
                    {/*                        title={value.name}*/}
                    {/*                        cost={value.cost}*/}
                    {/*                        image={value.img}*/}
                    {/*                        description={value.desc_short}*/}
                    {/*                        description_long={value.desc_long}*/}
                    {/*                        id={value.id}*/}
                    {/*                    />*/}
                    {/*                )*/}
                    {/*            }*/}
                    {/*        })*/}
                    {/*    )*/}

                    {/*})}*/}
                </div>
                <SimpleFeedback/>

            </main>

        </div>
    );
};

export default About;
import React, {useContext} from 'react';
import './Contacts.css'
import {Placemark, YMaps, Map} from "@pbe/react-yandex-maps";
import Background from "../../components/Background/Background";
import PageTitle from "../../components/PageTitle/PageTitle";
import SimpleFeedback from "../../components/SimpleFeedback/SimpleFeedback";
import UnableGasAlert from "../../components/UnableGasAlert/UnableGasAlert";
import {ContentContext} from "../../App";

const Contacts = () => {

    const content = useContext(ContentContext);

    const defaultState = {
        center: content.coordinates,
        zoom: 16
    };

    let mapSize = {}
    const screenWidth =  window.innerWidth
    if (screenWidth >= 1400){
        mapSize = {x: 1400, y: 500}
    }

    if (screenWidth <= 1400){
        mapSize = {x: 800, y: 300}
    }

    if (screenWidth <= 810){
        mapSize = {x: 350, y: 500}
    }

    return (
        <div className={'contacts'}>
            <UnableGasAlert/>
            <Background/>
            <main className={'content'}>
                <PageTitle top title={'Контакты'}/>
                <div className={'content__text-box'}>
                    <div className={"content__text"}>
                        <p>{content.phone}</p>
                        <p>{content.email}</p>
                    </div>
                    <div className={'content__text-flex'}>
                        <div className={'content__icons'}>
                            <a href={content.link_inst}>
                                <span></span>
                            </a>
                            <a  href={content.link_tg}>
                                <span></span>
                            </a>
                            <a className={'inactive_link'} href={'/'} onClick={()=>{return false}}>
                                <span></span>
                            </a>

                        </div>
                        <div className={'content__requisites'}>
                            <p>{content.ip_name}</p>
                            <br/>
                            <p>
                                ИНН {content.inn} <br/>
                                ОГРНИП {content.ogrn}
                            </p>
                        </div>
                    </div>

                </div>
                <h3 className={'contacts__address'}>{content.address}</h3>
                <p className={'contacts__alert'}>*Meta - признана экстремистской организацией в РФ.</p>
                <YMaps>
                    <Map
                        defaultState={defaultState}
                        width={mapSize.x}
                        height={mapSize.y}
                    >
                        <Placemark geometry={content.coordinates}/>
                    </Map>
                </YMaps>
                <SimpleFeedback/>
            </main>
        </div>
    );
};

export default Contacts;
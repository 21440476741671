import React, {useContext} from 'react';
import Background from "../../components/Background/Background";
import PageTitle from "../../components/PageTitle/PageTitle";
import CardServices from "../../components/CardServices/CardServices";
import './services.css'
import Calc from "../../components/Calc/Calc";
import UnableGasAlert from "../../components/UnableGasAlert/UnableGasAlert";
import {ContentContext} from "../../App";

const Services = () => {
    const content = useContext(ContentContext);
    return (
        <div className={'services'}>
            <UnableGasAlert/>
            <Background/>
            <main className={'content'}>
                <PageTitle top title={'Услуги'}></PageTitle>
                <div className={'services__card-box'}>
                    {content.services.map((value, index)=>{
                        return <CardServices
                            key={"card_" + index}
                            title={value.name}
                            cost={value.cost}
                            image={value.img}
                            description={value.desc_short}
                            description_long={value.desc_long}
                            id={value.id}
                        />
                    })}

                </div>
                <PageTitle title={'Калькулятор газификации'}></PageTitle>
                <Calc/>
            </main>
        </div>
    );
};

export default Services;